<template>
  <div>
    <div v-if="!isEditable">
      <span v-for="action in actions.taskTypes" :key="action" class="badge badge-light-success" style="margin: 2px;">
        {{ action }}
      </span>
    </div>
    <div v-else>
      <dx-util-tag-box
        id="store"
        v-model="selectedActions"
        :items="allActions"
        :show-selection-controls="true"
        :accept-custom-value="true"
        @value-changed="onValueChanged"
        @customItemCreating="onCustomItemCreating"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    actions: {
      type: Object,
      default: () => {},
    },
    day: {
      type: String,
      default: '',
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedActions: [],
      allActions: [
        'FBA', 'FBM', 'Receiving', 'Shipment', 'Problem', 'WFS',
      ],
    }
  },
  mounted() {
    this.selectedActions = [...this.actions.taskTypes]
  },
  methods: {
    onValueChanged(e) {
      const payload = {
        [this.day]: { taskTypes: this.selectedActions },
      }
      this.$emit('actions-updated', payload)
    },
    onCustomItemCreating(args) {
      const newValue = args.text
      const exists = this.allActions.some(item => item === newValue)
      if (!exists) {
        this.allActions.push(newValue)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
